import Siema from 'siema';

import { BULLET_ACTIVE_CLASS_NAME, BULLET_CLASS_NAME } from '../components/bullets/constants';

export function arrowAriaLabel(
    leftArrow: HTMLElement,
    rightArrow: HTMLElement,
    slideCount: number,
    currentSlide: number
): void {
    const prevSlide = currentSlide - 1;
    const nextSlide = currentSlide + 1;
    const leftArrowAriaLabel = `Go to slide ${prevSlide === 0 ? currentSlide : prevSlide}`;
    const rightArrowAriaLabel = `Go to slide ${nextSlide > slideCount ? currentSlide : nextSlide}`;
    leftArrow.setAttribute('aria-label', leftArrowAriaLabel);
    rightArrow.setAttribute('aria-label', rightArrowAriaLabel);
}

export const setActive = (bullet: HTMLElement): void => {
    bullet.classList.add(BULLET_ACTIVE_CLASS_NAME);
};

export const getBullets = (container: HTMLElement): Element[] => {
    return Array.from(container.querySelectorAll(`.${BULLET_CLASS_NAME}`));
};

export const clearAll = (bullets: Element[]): void => {
    // TODO: Find out a better approach to unhighlight a bullet
    bullets.forEach((bullet: Element): void => bullet.classList.remove(BULLET_ACTIVE_CLASS_NAME));
};

export const markActiveBullet = (container: HTMLElement, productSetCarousel: Siema, productsPerSlide: number): void => {
    const bulletIndex = Math.ceil(productSetCarousel.currentSlide / productsPerSlide);
    const bullets = getBullets(container);
    if (bullets.length) {
        clearAll(bullets);

        if (bullets[bulletIndex]) {
            setActive(bullets[bulletIndex] as HTMLElement);
        }
    }
};
