import { html, TemplateResult } from 'lit-html';

import { productPriceStyle } from '../components/productCards/styles';
import { TAX_OPTIONS } from '../constants';
import { ProductCardProps, ProductSetProps } from '../types/productProps';

function getProductPrice(productSetProps: ProductSetProps, productCardProps: ProductCardProps): TemplateResult {
    const productCurrencyNode =
        productSetProps._.data && productSetProps._.data.site && productSetProps._.data.site.currency;

    const formatPrice = (price: number): string => {
        const { symbol, symbolPlacement, decimalToken, decimalPlaces, thousandsToken } = productCurrencyNode.display;

        const replaceTokens = { '.': decimalToken, ',': thousandsToken };
        const formatDecimalPrice = price
            .toLocaleString('en-US', { minimumFractionDigits: decimalPlaces })
            .replace(/[.,]/g, match => replaceTokens[match]);

        return symbolPlacement === 'LEFT' ? `${symbol}${formatDecimalPrice}` : `${formatDecimalPrice}${symbol}`;
    };

    const productDisplayPrice = (price: number): string => {
        if (productCurrencyNode) {
            return formatPrice(price);
        }

        // If currencyNode is null, return intl format
        return new Intl.NumberFormat(productCardProps.displayLocale, {
            style: 'currency',
            currency: productCardProps.currencyCode,
        }).format(price);
    };

    const getProductPriceWithTax = (): TemplateResult => {
        let displayPriceWithTax = '';
        if (productCardProps.priceWithTax && productCardProps.currencyCode) {
            displayPriceWithTax = productDisplayPrice(productCardProps.priceWithTax);
        }

        return html`
            <div class="${productPriceStyle(productSetProps)}" data-test-id="product-set-widget-price">
                ${displayPriceWithTax}
            </div>
        `;
    };

    const getProductPriceWithoutTax = (): TemplateResult => {
        let displayPriceWithoutTax = '';
        if (productCardProps.priceWithoutTax && productCardProps.currencyCode) {
            displayPriceWithoutTax = productDisplayPrice(productCardProps.priceWithoutTax);
        }

        return html`
            <div class="${productPriceStyle(productSetProps)}" data-test-id="product-set-widget-price">
                ${displayPriceWithoutTax}
            </div>
        `;
    };

    const getProductPriceWithAndWithoutTax = (): TemplateResult => {
        let displayPriceWithTax = '';
        if (productCardProps.priceWithTax && productCardProps.currencyCode) {
            const formatter = new Intl.NumberFormat(productCardProps.displayLocale, {
                style: 'currency',
                currency: productCardProps.currencyCode,
            });
            displayPriceWithTax = `${formatter.format(productCardProps.priceWithTax)} (Inc. Tax)`;
        }

        let displayPriceWithoutTax = '';
        if (productCardProps.priceWithoutTax && productCardProps.currencyCode) {
            const formatter = new Intl.NumberFormat(productCardProps.displayLocale, {
                style: 'currency',
                currency: productCardProps.currencyCode,
            });
            displayPriceWithoutTax = `${formatter.format(productCardProps.priceWithoutTax)} (Ex. Tax)`;
        }

        return html`
            <div class="${productPriceStyle(productSetProps)}" data-test-id="product-set-widget-price">
                <div>${displayPriceWithTax}</div>
                <div>${displayPriceWithoutTax}</div>
            </div>
        `;
    };

    const dataNode = productSetProps._.data;
    const plpTaxSetting = dataNode && dataNode.site ? dataNode.site.settings.tax.plp : undefined;

    switch (plpTaxSetting) {
        case TAX_OPTIONS.INCLUDED:
            return getProductPriceWithTax();
        case TAX_OPTIONS.EXCLUDED:
            return getProductPriceWithoutTax();
        case TAX_OPTIONS.BOTH:
            return getProductPriceWithAndWithoutTax();
        default:
            return getProductPriceWithoutTax();
    }
}

export default getProductPrice;
