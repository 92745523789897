import Siema from 'siema';
import { html, TemplateResult } from 'lit-html';

import { LEFT_ARROW, RIGHT_ARROW } from '../arrows/constants';
import { arrowAriaLabel, clearAll, getBullets, markActiveBullet, setActive } from '../../services/handleSlideActions';
import { ProductSetProps } from '../../types/productProps';

import { productSetBullet, productSetBullets } from './styles';
import { BULLET_CLASS_NAME } from './constants';

const clickListener = (
    productSetCarousel: Siema,
    container: HTMLElement,
    event: Event,
    productsPerSlide: number | string
): void => {
    const bulletElement = event.target as HTMLElement;
    const bullets = getBullets(container);

    clearAll(bullets);

    const selected = parseInt(bulletElement.dataset.bulletIndex, 10);
    const productsPerSlideInt =
        typeof productsPerSlide === 'string' ? parseInt(productsPerSlide, 10) : productsPerSlide;
    productSetCarousel.goTo(productsPerSlideInt * selected);
    setActive(bullets[selected] as HTMLElement);
};

export const setupCarouselBullets = (
    container: HTMLElement,
    productSetCarousel: Siema,
    productsPerSlide: number,
    getEditMode: () => boolean,
    slideCount: number
): void => {
    markActiveBullet(container, productSetCarousel, productsPerSlide);
    const leftArrow = container.querySelector(`.${LEFT_ARROW}`) as HTMLElement;
    const rightArrow = container.querySelector(`.${RIGHT_ARROW}`) as HTMLElement;
    getBullets(container).forEach(
        (bullet: Element): void => {
            bullet.addEventListener(
                'click',
                (event: Event): void => {
                    const editMode = getEditMode();
                    if (editMode) {
                        return;
                    }
                    clickListener(productSetCarousel, container, event, productsPerSlide);
                    if (leftArrow && rightArrow) {
                        const currentSlide = Math.ceil(productSetCarousel.currentSlide / productsPerSlide) + 1;
                        arrowAriaLabel(leftArrow, rightArrow, slideCount, currentSlide);
                    }
                }
            );
        }
    );
};

export const productSetBulletElement = (numberOfBullets: number): TemplateResult[] => {
    const bullets = [];

    for (let i = 0; i < numberOfBullets; i += 1) {
        const productHtml = html`
            <button
                class="${productSetBullet} ${BULLET_CLASS_NAME}"
                data-bullet-index="${i}"
                data-test-id="product-set-bullet-${i}"
                aria-label="Go to slide ${i + 1}"
            ></button>
        `;

        bullets.push(productHtml);
    }

    return bullets;
};

export const productSetBulletBlock = (props: ProductSetProps, bulletsEnabled: boolean): TemplateResult => {
    const { productsPerSlide, product } = props;
    const productsPerSlideInt =
        typeof productsPerSlide === 'string' ? parseInt(productsPerSlide, 10) : productsPerSlide;
    const numberOfBullets = Math.ceil(product.value.length / productsPerSlideInt);
    const productBullets = productSetBulletElement(numberOfBullets);

    return html`
        <div class="${productSetBullets(bulletsEnabled)}">${productBullets}</div>
    `;
};
