import renderProduct from './services/productRenderer';
import { ProductSetProps } from './types/productProps';
import ProductSetWidget from './types/productWidget';
import { productSetCarouselId, productSetContainerId } from './constants';
import { setupCarouselArrows } from './components/arrows/arrows';
import isEditModeDisabled from './lib/isEditModeDisabled';
import { setupCarouselBullets } from './components/bullets/bullets';

// eslint-disable-next-line import/order
import Siema = require('siema');

export default class ProductSet implements ProductSetWidget {
    private props: ProductSetProps;

    public carousel: Siema;

    private readonly containerSelector: string;

    private readonly productSetSelector: string;

    public readonly uuid: string;

    public constructor(props: ProductSetProps) {
        this.props = props;
        this.uuid = this.props._.id;
        this.containerSelector = productSetContainerId(this.uuid);
        this.productSetSelector = productSetCarouselId(this.uuid);
    }

    public getEditMode = (): boolean => !isEditModeDisabled(this.getProps());

    public goTo = (index: number): void => {
        this.carousel.goTo(index);
    };

    public setProps = (props: ProductSetProps): void => {
        this.props = props;
    };

    public getContainer = (): HTMLElement | null => document.querySelector(`${this.containerSelector}`);

    public getProps = (): ProductSetProps => this.props;

    public setup = (): void => {
        this.render();
        if (this.getContainer()) {
            this.initializeCarousel();
        }
    };

    public render = (): void => {
        renderProduct(this.getProps());
    };

    public initializeCarousel = (): void => {
        const { productsPerSlide, product } = this.props;
        this.carousel = new Siema({
            autoPlay: false,
            draggable: false,
            draggableOnMobile: true,
            duration: 400,
            easing: 'ease',
            loop: false,
            perPage: {
                0: 2,
                800: productsPerSlide,
            },
            preserveInitialState: true,
            selector: this.productSetSelector,
            startIndex: 0,
        });

        const productsPerSlideInt =
            typeof productsPerSlide === 'string' ? parseInt(productsPerSlide, 10) : productsPerSlide;
        const numberOfSlides = Math.ceil(product.value.length / productsPerSlideInt);

        setupCarouselArrows(this.getContainer(), this.carousel, productsPerSlideInt, this.getEditMode, numberOfSlides);
        setupCarouselBullets(this.getContainer(), this.carousel, productsPerSlideInt, this.getEditMode, numberOfSlides);
    };
}
