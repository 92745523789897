import { Alignment, ButtonStyle, ImageFit, Visibility } from '@bigcommerce/page-builder-sdk';

import { ActionTypes } from './actions';
import { CurrencyElement, ProductImageSizes, ProductsDynamicData } from './productDynamicData';

export interface ProductImageElement {
    visibility: Visibility;
    imageFit?: ImageFit;
}

export interface ProductProtectedNamespace {
    context?: {
        activeCurrencyCode: string;
    };
    id: string;
    pageBuilderData?: PageBuilderData;
    data?: ProductsDynamicData;
}

export interface PageBuilderData {
    previewState?: PreviewState;
}

export interface PreviewState {
    editMode?: boolean;
}

interface FontSize {
    type: string;
    value: number;
}

export interface BrandElement {
    color: string;
    fontFamily: string;
    fontWeight: number;
    fontSize: FontSize;
    visibility: Visibility;
    textStyle: TextStyle;
}

export interface ProductNameElement {
    color: string;
    fontFamily: string;
    fontWeight: number;
    fontSize: FontSize;
    visibility: Visibility;
    textStyle: TextStyle;
}

export interface PriceElement {
    color: string;
    fontFamily: string;
    fontWeight: number;
    fontSize: FontSize;
    visibility: Visibility;
    textStyle: TextStyle;
}

interface ProductRatingElement {
    visibility: Visibility;
    starColorFilled: string;
    starColorEmpty: string;
    reviewsColor: string;
}

interface ButtonElement {
    visibility: Visibility;
    shape: ButtonStyle;
    buttonActionType: ActionTypes;
    buttonColor: string;
    buttonTextColor: string;
    textStyle: TextStyle;
}

export interface ProductValue {
    productId: string;
    productName: string;
    thumbnailUrl: string;
}

interface Product {
    type: string;
    value: ProductValue[];
}

export enum TextStyle {
    BODY_TEXT = 'global_typography_body_text',
    CUSTOM = 'custom',
    DEFAULT = 'default',
    HEADING_1 = 'global_typography_h1',
    HEADING_2 = 'global_typography_h2',
    HEADING_3 = 'global_typography_h3',
    HEADING_4 = 'global_typography_h4',
    HEADING_5 = 'global_typography_h5',
    HEADING_6 = 'global_typography_h6',
    SECONDARY_TEXT = 'global_typography_secondary_text',
}

export interface ProductSetProps {
    _: ProductProtectedNamespace;
    brand: BrandElement;
    button: ButtonElement;
    displayLocale?: string;
    price: PriceElement;
    product: Product;
    productCardContentAlignment: Alignment;
    productImage: ProductImageElement;
    productName: ProductNameElement;
    productRating: ProductRatingElement;
    productsPerSlide: number | string;
    textColor: string;
}

export interface ProductCardProps {
    actionLabel: string;
    actionLink: string;
    brand: string;
    displayLocale: string;
    currencyCode?: string;
    currencyDisplay?: CurrencyElement;
    name: string;
    priceWithTax?: number;
    priceWithoutTax?: number;
    path?: string;
    productImages?: ProductImageSizes;
    productImageFit: ImageFit;
    rating: number;
    starColorEmpty: string;
    starColorFilled: string;
    totalReviews: number;
}
