import Siema from 'siema';
import { html, TemplateResult } from 'lit-html';

import { arrowAriaLabel, markActiveBullet } from '../../services/handleSlideActions';

import { arrowStyles, productSetArrowsStyles } from './styles';
import { ArrowSide, LEFT_ARROW, leftArrowIcon, RIGHT_ARROW, rightArrowIcon } from './constants';

export const setupCarouselArrows = (
    container: HTMLElement,
    carousel: Siema,
    productsPerSlide: number | string,
    getEditMode: () => boolean,
    slideCount: number
): void => {
    const leftArrow = container.querySelector(`.${LEFT_ARROW}`) as HTMLElement;
    const rightArrow = container.querySelector(`.${RIGHT_ARROW}`) as HTMLElement;

    const productsPerSlideInt =
        typeof productsPerSlide === 'string' ? parseInt(productsPerSlide, 10) : productsPerSlide;

    const mediaQuery = 'screen and (min-width: 801px)';

    if (leftArrow && rightArrow) {
        leftArrow.addEventListener(
            'click',
            (): void => {
                const editMode = getEditMode();
                if (editMode) {
                    return;
                }
                const slidesToScroll = window.matchMedia(mediaQuery).matches ? productsPerSlideInt : 2;
                carousel.prev(slidesToScroll);
                markActiveBullet(container, carousel, slidesToScroll);
                const currentSlide = Math.ceil(carousel.currentSlide / slidesToScroll) + 1;
                arrowAriaLabel(leftArrow, rightArrow, slideCount, currentSlide);
            }
        );
        rightArrow.addEventListener(
            'click',
            (): void => {
                const editMode = getEditMode();
                if (editMode) {
                    return;
                }
                const slidesToScroll = window.matchMedia(mediaQuery).matches ? productsPerSlideInt : 2;
                carousel.next(slidesToScroll);
                markActiveBullet(container, carousel, slidesToScroll);
                const currentSlide = Math.ceil(carousel.currentSlide / slidesToScroll) + 1;
                arrowAriaLabel(leftArrow, rightArrow, slideCount, currentSlide);
            }
        );
    }
};

const arrows = (isArrowsVisibleMobileView: boolean, isArrowsVisibleDesktopView: boolean): TemplateResult => {
    return html`
        <div class="${productSetArrowsStyles(isArrowsVisibleMobileView, isArrowsVisibleDesktopView)}">
            <button
                class="${arrowStyles(ArrowSide.LEFT)} ${LEFT_ARROW}"
                aria-label="Go to slide 1"
                data-test-id="product-set-left-arrow"
            >
                ${leftArrowIcon}
            </button>
            <button
                class="${arrowStyles(ArrowSide.RIGHT)} ${RIGHT_ARROW}"
                aria-label="Go to slide 2"
                data-test-id="product-set-right-arrow"
            >
                ${rightArrowIcon}
            </button>
        </div>
    `;
};

export default arrows;
